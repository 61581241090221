export const constants = {

    api: {
        parceiro: 'TEGG',
    },

    texts: {
        nomeEmpresa: 'TEGG',
    },

    links: {
        linkPedirChip: 'https://teggtelecom.com.br/',
        linkApp: '',
        linkWpp: 'https://wa.me/5544920012076',
        linkMapaCobertura: 'https://tim.img.com.br/mapa-cobertura/',
        linkTenhaSuaOperadora: null,
        linkTenhaSuaOperadora: 'https://playtec.net', // INFINITI Apenas
        linkRastrearMeuPedido: 'https://tracking.totalexpress.com.br/',
        linkPoliticaDePrivacidade: 'https://privacidade.operadora.app.br/#/Play',
        linkTermoDeAdesao: 'https://privacidade.operadora.app.br/#/infiniti',
        linkInstagram: 'https://www.instagram.com/teggoficial/',
    },

}